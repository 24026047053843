import React, { LabelHTMLAttributes } from 'react';
import { Component } from '@/types/interfaces';

export interface LabelProps
  extends Component,
    Pick<LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor'> {}

const Label: React.FC<LabelProps> = ({
  className = '',
  children,
  ...props
}) => (
  <label className={`label ${className}`} {...props}>
    {children}
  </label>
);

export default Label;
